import React from 'react';
import '../styles/Teams.css';

export default class Teams extends React.Component{
    render(){return(
        <div className="teams-container" id="teams">
            <div className="t-background-container">
            <svg viewBox="0 0 1174 795" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0.101787 350.455C5.10179 196.955 68.6018 25.4547 283.602 1.95469C498.602 -21.5453 545.997 174.29 656.602 190.455C790.053 209.958 1002.71 13.2647 1129.6 58.9547C1255.74 104.374 1068.92 339.283 1049.6 471.955C1029.59 609.399 1013.05 671.301 889.602 734.955C739.308 812.451 555.102 809.955 365.102 754.955C175.102 699.955 -4.89821 503.955 0.101787 350.455Z" fill="#C4C4C4" fillOpacity="0.04">
                <animate dur="15s" repeatCount="indefinite" attributeName="d" 
                calcMode="spline"
                keySplines="0.33 0 0.33 1; 0.33 0 0.33 1"
                values="M0.101787 350.455C5.10179 196.955 68.6018 25.4547 283.602 1.95469C498.602 -21.5453 545.997 174.29 656.602 190.455C790.053 209.958 1002.71 13.2647 1129.6 58.9547C1255.74 104.374 1068.92 339.283 1049.6 471.955C1029.59 609.399 1013.05 671.301 889.602 734.955C739.308 812.451 555.102 809.955 365.102 754.955C175.102 699.955 -4.89821 503.955 0.101787 350.455Z;M1.00006 310C6.00006 156.5 -32.3982 27.4547 182.602 3.95471C397.602 -19.5453 491.395 85.8357 602 102C735.451 121.503 901.708 15.2647 1028.6 60.9547C1154.74 106.374 864.315 267.328 845 400C824.99 537.444 716.395 602.44 582 637.5C524.5 652.5 448 640.5 258 585.5C68 530.5 -3.99994 463.5 1.00006 310Z; M0.101787 350.455C5.10179 196.955 68.6018 25.4547 283.602 1.95469C498.602 -21.5453 545.997 174.29 656.602 190.455C790.053 209.958 1002.71 13.2647 1129.6 58.9547C1255.74 104.374 1068.92 339.283 1049.6 471.955C1029.59 609.399 1013.05 671.301 889.602 734.955C739.308 812.451 555.102 809.955 365.102 754.955C175.102 699.955 -4.89821 503.955 0.101787 350.455Z" />
                </path>
            </svg>
            <svg viewBox="0 0 573 418" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M213.281 379.886C145.222 314.013 247.748 204.273 178.281 139.886C128.238 93.5021 50.2431 147.193 10.2809 91.8861C-48.9364 9.93111 162.612 46.3248 263.281 36.8861C374.767 26.4332 491.34 -47.1287 549.781 48.3861C586.468 108.347 574.206 162.472 549.781 228.386C499.705 363.522 316.835 480.115 213.281 379.886Z" fill="#C4C4C4" fillOpacity="0.03">
                <animate dur="10s" repeatCount="indefinite" attributeName="d" 
                calcMode="spline"
                keySplines="0.33 0 0.33 1; 0.33 0 0.33 1"
                values="M213.281 379.886C145.222 314.013 247.748 204.273 178.281 139.886C128.238 93.5021 50.2431 147.193 10.2809 91.8861C-48.9364 9.93111 162.612 46.3248 263.281 36.8861C374.767 26.4332 491.34 -47.1287 549.781 48.3861C586.468 108.347 574.206 162.472 549.781 228.386C499.705 363.522 316.835 480.115 213.281 379.886Z;M251 366C196.5 352.11 199.967 282.887 130.5 218.5C80.4568 172.116 53.4622 226.807 13.5 171.5C-45.7172 89.545 106.331 33.4387 207 24C318.487 13.5471 461.559 -38.0148 520 57.5C556.688 117.461 472.925 212.086 448.5 278C398.424 413.136 284.5 374.538 251 366Z; M213.281 379.886C145.222 314.013 247.748 204.273 178.281 139.886C128.238 93.5021 50.2431 147.193 10.2809 91.8861C-48.9364 9.93111 162.612 46.3248 263.281 36.8861C374.767 26.4332 491.34 -47.1287 549.781 48.3861C586.468 108.347 574.206 162.472 549.781 228.386C499.705 363.522 316.835 480.115 213.281 379.886Z" />
                </path>
            </svg>

            </div>
            <div className='t-title'>
                <span>Meet the</span><br/>
                <span>Team</span><br/>
            </div>
            
            {/* <div className="t-title-section">
            <picture>
                <source srcset="./images/welcome/pharma_logo_2022.svg" media="(max-width: 600px)"/>
                <img src="./images/welcome/pharma_logo_2022.svg" alt="Pharmahacks 2022" className="-title-text-svg" />
            </picture> 
            <br/>
                <Button value="Apply now" color="red" action="https://docs.google.com/forms/d/e/1FAIpQLSfZqKl6JNv9ZYKloHqidzdJ8I4jg1Fp3WqcEL01wmBJORUxHQ/viewform" />
            </div>
            <img className="t-dna-gif" src="./images/welcome/DNA.gif" alt="DNA gif" /> */}
        </div>
    )}
}